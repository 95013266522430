
import accountSignup from 'mixins/accountSignup';
import analytics from 'mixins/analytics';
import MenuNavigation from '../components/menus/menu-navigation';
import MenuFooter from '../components/menus/menu-footer';
import ipCountry from 'mixins/ipCountry';
import modal from 'mixins/modal';
import throttle from 'lodash/throttle';
import { ModalsContainer } from 'vue-final-modal';
import MarketoForm from './../components/marketo-form';
import lazyLoadScript from 'mixins/lazyLoadScript';

export default {
  components: {
    MenuNavigation,
    MenuFooter,
    ModalsContainer,
    MarketoForm,
  },
  mixins: [ipCountry, accountSignup, analytics, modal, lazyLoadScript],
  middleware: ['region', 'setStoreData'],
  data() {
    return {
      isScrolled: false,
      isUserActive: false,
    };
  },
  mounted() {
    // we want the user to access the website directly on his geo
    this.$store.commit('setRegion', this.$store.state.ipCountry);
    window.addEventListener('scroll', throttle(this.handleScroll, 500));
  },
  beforeMount() {
    // Facebook, LinkedIn ads are constructed with UTM
    // if we detect any we can load the script immediately to catch it as soon as possible
    if (document.location.href.includes('utm')) {
      this.$store.commit('setLoadAssets', 'segment,facebook,linkedin');
    }
    // if we detect the gclid parameter in the url we should load immediately segment
    if (document.location.href.includes('gclid')) {
      this.$store.commit('setLoadAssets', 'segment');
    }
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.handleScroll);
  },
  methods: {
    handleScroll() {
      this.isScrolled = window.scrollY !== 0;
      this.isUserActive = true;
      this.loadAssets();
    },
    mouseEnter() {
      this.isUserActive = true;
      this.loadAssets();
    },
    loadAssets() {
      this.$store.commit('setLoadAssets', 'all');
    },
  },
};
